import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Header from "../components/Header"
import AccordionGreen from "../components/AccordionGreen"
import Footer from "../components/Footer/Footer"
import Button from "../components/Button"
import Content from "../components/Content"
import { openPopupWidget, InlineWidget } from "react-calendly"
import { Modal } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import Seo from "../components/SEO"
import Marquee from "react-fast-marquee"
import ModalVideo from "react-modal-video"

// const openCalendly = () => {
//   //const onClick = () => openPopupWidget({ url });

//   // <Popover id="popover-basic" style={{maxWidth: "100%"}}>
//   //   {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
//   //   <Popover.Content>
//   //     <InlineWidget url="https://calendly.com/workeye/personal-demo"/>
//   //     {/* {openPopupWidget( "https://calendly.com/workeye" )} */}
//   //   </Popover.Content>
//   // </Popover>

//   //return <button className="btn btn-purple" onClick={onClick}>Let's arrange a demo</button>;
// };

// const popover = (
//   <Popover id="popover-basic" style={{maxWidth: "100%"}}>
//     {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
//     <Popover.Content>
//       <InlineWidget url="https://calendly.com/workeye/personal-demo"/>
//       {/* {openPopupWidget( "https://calendly.com/workeye" )} */}
//     </Popover.Content>
//   </Popover>
// );

// const CalendlyButton = () => (
//   <OverlayTrigger trigger="click" placement="right" overlay={popover}>
//     <button className="btn btn-purple" variant="success">Let's arrange a demo</button>
//   </OverlayTrigger>
// );

const Services = ({ data }) => {
  const {
    allContentfulTogglesServices,
    allContentfulServices,
    allContentfulContentArea,
    allContentfulHeader,
    allContentfulSeo,
  } = data

  const [visible, setVisible] = useState(false)
  const [isOpen, setOpen] = useState(false)

  return (
    <div id="servicesPage">
      <Seo
        title={allContentfulSeo.nodes[2].title}
        description={allContentfulSeo.nodes[2].description}
        keywords={allContentfulSeo.nodes[2].keywords}
      />
      <Header />
      <section className="contact-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 contact-tagline">
              <h3 className="headings-of-all">
                {allContentfulHeader.nodes[0].btnTwo}
              </h3>
              <div>
                <h2>
                  {documentToReactComponents(
                    JSON.parse(allContentfulServices.nodes[0].description.raw)
                  )}
                </h2>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 img-col ">
              <img
                className="img"
                src={allContentfulServices.nodes[0].image.file.url}
                alt="ser-1"
              />
              <div
                className="ser-padding-btn"
                style={{ textAlign: "center", marginTop: "1.3rem" }}
              >
                <Link to="/test-drive">
                  <Button
                    text={allContentfulServices.nodes[0].testDriveButton}
                    className="btn-purple bigger-btn"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section-padding-2">
        <Content
          main1={allContentfulContentArea.nodes[1].textMain1.textMain1}
          main2={allContentfulContentArea.nodes[1].textMain2.textMain2}
          side1={allContentfulContentArea.nodes[1].textSide1.textSide1}
          side2={allContentfulContentArea.nodes[1].textSide2.textSide2}
          className="gr-bg"
        />
      </div>

      <ModalVideo
        channel="custom"
        isOpen={isOpen}
        autoplay
        url={allContentfulServices.nodes[0].servicesVideo}
        onClose={() => setOpen(false)}
      />

      <div className="container text-center section-padding-2">
        <span id="sample-use-cases">
          {allContentfulServices.nodes[0].playVideoText} &nbsp;
          <img
            src={allContentfulServices.nodes[0].playVideoIcon.file.url}
            alt="Play Video Icon"
            onClick={() => setOpen(true)}
            style={{ cursor: "pointer" }}
          />
        </span>
      </div>

      <section className="section-padding d-flex">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div>
                  {allContentfulTogglesServices.nodes.map((v, i) => (
                    <AccordionGreen
                      title={v.title}
                      content={documentToReactComponents(
                        JSON.parse(v.description.raw)
                      )}
                      key={i}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="ser-slider">
        <marquee scrollamount="10">
          {documentToReactComponents(
            JSON.parse(allContentfulServices.nodes[0].sliderText.raw)
          )}
        </marquee>
      </div> */}
      <Marquee className="slider-text" gradient={false} speed={100}>
        {documentToReactComponents(
          JSON.parse(allContentfulServices.nodes[0].sliderText.raw)
        )}
        <span className="tab"></span>
      </Marquee>

      <section className="xiir-benefits">
        <div className="container">
          <div className="div-head">
            <h1 className="colorOrange">
              {allContentfulServices.nodes[0].titleOne}
            </h1>
            <h3 className="ser-head-2">
              {allContentfulServices.nodes[0].sideTitle}
            </h3>
          </div>
          <br />
          <div className="div-para">
            <h4>{allContentfulServices.nodes[0].descriptionPlans}</h4>
          </div>
        </div>
      </section>

      <div className="text-center container">
        <div
          className="col-sm-12 col-md-9 col-lg-7"
          style={{ margin: "2.5rem auto 5.5rem" }}
        >
          <div className="test-drive-plan-div" id="demo-div">
            <div className="test-drive-plan-head">
              <img
                src={allContentfulServices.nodes[0].demoIcon.file.url}
                alt="Personalised Demo Icon"
              />
              <h3 className="ser-head-2">
                {allContentfulServices.nodes[0].demoTitle}
              </h3>
            </div>
            <div className="test-drive-plan-details">
              <ReactMarkdown
                children={
                  allContentfulServices.nodes[0].demoDescription.demoDescription
                }
              />
            </div>
            <div className="test-drive-plan-foot">
              <button
                type="button"
                onClick={() => setVisible(true)}
                className="btn btn-purple"
              >
                {allContentfulServices.nodes[0].demoButton}
              </button>
              {/* </Link> */}
              {/* <CustomButton url={"https://calendly.com/workeye"}/> */}
              {/* <CalendlyButton /> */}
              <Modal show={visible} onHide={() => setVisible(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Book a Demo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <InlineWidget url="https://calendly.com/workeye/personal-demo" />
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>

      {/*<div className="container ser-padding-btn text-center">*/}
      {/* <Link to="/contact"> */}
      {/*</div>*/}
      <Footer />
    </div>
  )
}

export default Services

export const query = graphql`
  query {
    allContentfulTogglesServices(sort: { fields: createdAt, order: DESC }) {
      nodes {
        description {
          raw
        }
        title
      }
    }
    allContentfulContentArea(sort: { fields: createdAt }) {
      nodes {
        textMain1 {
          textMain1
        }
        textMain2 {
          textMain2
        }
        textSide1 {
          textSide1
        }
        textSide2 {
          textSide2
        }
      }
    }
    allContentfulServices {
      nodes {
        sliderText {
          raw
        }
        sideTitle
        imagePlan {
          file {
            url
          }
          title
        }
        description {
          raw
        }
        descriptionPlans
        image {
          file {
            url
          }
        }
        titleOne
        demoIcon {
          file {
            url
          }
        }
        demoTitle
        demoDescription {
          demoDescription
        }
        demoButton
        playVideoText
        servicesVideo
        playVideoIcon {
          file {
            url
          }
        }
        testDriveButton
      }
    }
    allContentfulHeader {
      nodes {
        btnTwo
      }
    }
    allContentfulSeo(sort: { fields: createdAt }) {
      nodes {
        title
        description
        keywords
      }
    }
  }
`
